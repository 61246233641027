<template>
  <div>
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Due Date</th>
          <th>Amount</th>
          <th>Currency</th>
          <th>Status</th>
          <th>Contract ID</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="invoices.length > 0">
        <tr v-for="(invoice, i) in invoices" :key="i">
          <td>{{invoice.id}}</td>
          <td>{{invoice.due_date}}</td>
          <td>{{invoice.amount}}</td>
          <td>{{invoice.currency}}</td>
          <td>{{invoice.is_paid}}</td>
          <td>{{invoice.contract_id}}</td>
          <td>
            <div class="buttons">
              <button class="button" @click="editInvoice(invoice)">Edit</button>
              <button class="button is-success" @click="showCreatePaymentModal(invoice)">Create Payment</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Edit Invoice Modal -->
    <modal v-if="showEditModal" @close="showEditModal = false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Invoice</p>
          <button 
            class="delete" 
            aria-label="close"
            @click="showEditModal = false"
          ></button>
        </header>
        
        <section class="modal-card-body">
          <form @submit.prevent="handleEditSubmit">
            <div class="field">
              <label class="label">Due Date</label>
              <div class="control">
                <input 
                  type="datetime-local" 
                  class="input"
                  v-model="editDateLocal"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Amount</label>
              <div class="control">
                <input 
                  type="number"
                  step="0.01" 
                  class="input"
                  v-model="editForm.amount"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Currency</label>
              <div class="control">
                <div class="select">
                  <select v-model="editForm.currency">
                    <option value="USD">USD</option>
                    <option value="TL">TL</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label">Is Paid</label>
              <div class="control">
                <div class="select">
                  <select v-model="editForm.is_paid">
                    <option value="true">Paid</option>
                    <option value="false">Not Paid</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <button type="submit" class="button is-primary">Update Invoice</button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </modal>

    <!-- Create Payment Modal -->
    <modal v-if="showPaymentModal" @close="showPaymentModal = false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Payment</p>
          <button 
            class="delete" 
            aria-label="close"
            @click="showPaymentModal = false"
          ></button>
        </header>
        
        <section class="modal-card-body">
          <form @submit.prevent="handlePaymentSubmit">
            <div class="field">
              <label class="label">Amount *</label>
              <div class="control">
                <input 
                  type="number"
                  step="0.01" 
                  class="input"
                  v-model="paymentForm.amount"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Description *</label>
              <div class="control">
                <textarea 
                  class="textarea"
                  v-model="paymentForm.description"
                  required
                ></textarea>
              </div>
            </div>

            <div class="field">
              <p class="help">* Required fields</p>
              <div class="control">
                <button type="submit" class="button is-primary">Create Payment</button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal
  },
  data() {
    return {
      invoices: [],
      showEditModal: false,
      editForm: {
        id: null,
        due_date: '',
        amount: null,
        currency: '',
        is_paid: '',
        contract_id: null
      },
      showPaymentModal: false,
      paymentForm: {
        invoice_id: null,
        amount: null,
        description: ''
      }
    };
  },
  computed: {
    ...mapState(["selectedCompany"]),
    editDateLocal: {
      get() {
        return this.formatToLocal(this.editForm.due_date);
      },
      set(value) {
        this.editForm.due_date = this.formatToBackend(value);
      }
    }
  },
  methods: {
    getInvoices() {
      this.$http.get(`invoices?page=1&size=20&company_id=${this.selectedCompany.id}`).then((response) => {
        this.invoices = response.data.data;
      });
    },
    editInvoice(invoice) {
      this.editForm = {
        id: invoice.id,
        due_date: invoice.due_date,
        amount: invoice.amount,
        currency: invoice.currency,
        is_paid: invoice.is_paid,
        contract_id: invoice.contract_id
      };
      this.showEditModal = true;
    },
    formatToLocal(backendDate) {
      if (!backendDate) return '';
      return backendDate.slice(0, -4);
    },
    formatToBackend(localDate) {
      if (!localDate) return '';
      return localDate + ':00Z';
    },
    async handleEditSubmit() {
      try {
        const formData = new FormData();
        formData.append("id", this.editForm.id);
        formData.append("due_date", this.editForm.due_date);
        formData.append("amount", this.editForm.amount);
        formData.append("currency", this.editForm.currency);
        formData.append("status", this.editForm.status);
        formData.append("contract_id", this.editForm.contract_id);

        const response = await this.$http.patch("invoices/", formData);

        if (response.status === 200) {
          this.$toast.open({
            position: "top",
            type: "success",
            message: "Invoice updated successfully"
          });
          this.showEditModal = false;
          this.getInvoices();
        }
      } catch (error) {
        console.error('Error updating invoice:', error);
        this.$toast.open({
          position: "top",
          type: "error",
          message: "Error occurred"
        });
      }
    },
    showCreatePaymentModal(invoice) {
      this.paymentForm.invoice_id = invoice.id;
      // Varsayılan olarak invoice amount'u payment amount olarak set edebiliriz
      this.paymentForm.amount = invoice.amount;
      this.showPaymentModal = true;
    },
    async handlePaymentSubmit() {
      try {
        if (!this.paymentForm.amount || !this.paymentForm.description) {
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Please fill all required fields"
          });
          return;
        }

        const formData = new FormData();
        Object.keys(this.paymentForm).forEach(key => {
          formData.append(key, this.paymentForm[key]);
        });

        const response = await this.$http.post("payments", formData);

        // 200 veya 201 status kodlarını başarılı kabul et
        if (response.status === 200 || response.status === 201) {
          this.$toast.open({
            position: "top",
            type: "success",
            message: "Payment created successfully"
          });
          this.showPaymentModal = false;
          // Ödeme başarılı olduysa invoice listesini yenile
          this.getInvoices();
        }
      } catch (error) {
        console.error('Error creating payment:', error);
        this.$toast.open({
          position: "top",
          type: "error",
          message: "Error occurred"
        });
      }
    }
  },
  mounted() {
    this.getInvoices();
  }
};
</script>

<style>

</style>
   