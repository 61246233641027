<template>
  <table class="table is-fullwidth is-striped is-hoverable">
       <thead>
         <tr>
           <th>Created At</th>
           <th>Amount</th>
           <th>Currency</th>
           <th>Description</th>
           <th></th>
         </tr>
       </thead>
       <tbody>
        <tr v-for="(payment, i) in payments" :key="i" @click="edit(invoice)">
          <td>{{convertISO8601ToTurkeyTime(payment.created_at)}}</td>
          <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="payments[i].amount" /></div></td>
          <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="payments[i].currency" /></div></td>
          <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="payments[i].description" /></div></td>
          <td><div class="control"><button class="button" @click="save(payments[i])">Save</button></div></td>
        </tr>
       </tbody>
     </table>
 </template>
 
 <script>
 import { mapState } from "vuex";
 import { convertISO8601ToTurkeyTime } from "../../../../utils/date_converter";
 
 export default {
   data() {
     return {
       payments: [],
       newPayment: Object
     };
   },
   computed: {
     ...mapState(["selectedContract"]),
   },
   methods:{
    convertISO8601ToTurkeyTime,
     getPayments(){
       this.$http.get(`payments?page=1&size=20&contract_id=${this.selectedContract.id}`).then((response) => {
         this.payments = response.data.data
       });
     },

    save(payment) {
      var formData = new FormData();
      formData.append("id", payment.id);
      formData.append("amount", payment.amount);
      formData.append("currency", payment.currency);
      formData.append("description", payment.description);

      this.$http.patch("payments/", formData).then((response) => {
        if (response.status !== 200) {
          this.$toast.open({
            position: "top",
            type: "error",
            message:
              "Error occurred",
          });
          return;
        }
        this.$toast.open({
          position: "top",
          type: "success",
          message: "Updated",
        });
        
      });

      setTimeout(() => {
        this.getInvoices();    
      }, 1000);
    

    },
     
   },
   mounted() {
     this.getPayments();
   },
 };
 </script>
 
 <style>
 
 </style>
 