<template>
  <div>
    <!-- Add Contract Button -->
    <div class="mb-4">
      <button 
        class="button is-primary"
        @click="showAddContractModal = true"
      >
        Add New Contract
      </button>
    </div>

    <!-- Contracts Table -->
    <div class="table-container">
      <table class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Is Trial</th>
            <th>Amount</th>
            <th>Start</th>
            <th>End</th>
            <th>Next Invoice</th>
            <th>User Count</th>
            <th>Camera Count</th>
            <th>Project Count</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="contracts.length > 0">
          <tr v-for="(contract, i) in contracts" :key="i">
            <td>{{contract.id}}</td>
            <td>{{contract.is_trial}}</td>
            <td>{{contract.amount}}</td>
            <td>{{contract.start_date}}</td>
            <td>{{contract.end_date?.Time || '-'}}</td>
            <td>{{contract.next_invoice_date?.Time || '-'}}</td>
            <td>{{contract.features.user_count}}</td>
            <td>{{contract.features.camera_count}}</td>
            <td>{{contract.features.project_count}}</td>
            <td>
              <div class="buttons">
                <button class="button" @click="editContract(contract)">Edit</button>
                <button class="button is-info" @click="showAddInvoiceModal(contract)">Add Invoice</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Contract Modal -->
    <modal
      v-if="showAddContractModal"
      @close="showAddContractModal = false"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add New Contract</p>
          <button 
            class="delete" 
            aria-label="close"
            @click="showAddContractModal = false"
          ></button>
        </header>
        
        <section class="modal-card-body">
          <form @submit.prevent="handleContractSubmit">
            <div class="field">
              <label class="label">Start Date *</label>
              <div class="control">
                <input 
                  type="datetime-local" 
                  class="input"
                  v-model="startDateLocal"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">End Date *</label>
              <div class="control">
                <input 
                  type="datetime-local" 
                  class="input"
                  v-model="endDateLocal"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Package *</label>
              <div class="control">
                <div class="select">
                  <select v-model="contractForm.package_id" required>
                    <option value="">Select Package</option>
                    <option value="1">Basic</option>
                    <option value="2">Pro</option>
                    <option value="3">Enterprise</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label">User Count *</label>
              <div class="control">
                <input 
                  type="number" 
                  class="input"
                  v-model="contractForm.user_count"
                  min="1"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Camera Count *</label>
              <div class="control">
                <input 
                  type="number" 
                  class="input"
                  v-model="contractForm.camera_count"
                  min="1"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Project Count *</label>
              <div class="control">
                <input 
                  type="number" 
                  class="input"
                  v-model="contractForm.project_count"
                  min="1"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Currency *</label>
              <div class="control">
                <div class="select">
                  <select v-model="contractForm.currency" required>
                    <option value="">Select Currency</option>
                    <option value="USD">USD</option>
                    <option value="TL">TL</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label">Amount *</label>
              <div class="control">
                <input 
                  type="number" 
                  step="0.01"
                  class="input"
                  v-model="contractForm.amount"
                  required
                >
              </div>
            </div>

            <div class="field">
              <p class="help">* Required fields</p>
              <div class="control">
                <button type="submit" class="button is-primary">Save Contract</button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </modal>

    <!-- Edit Contract Modal -->
    <modal v-if="showEditModal" @close="showEditModal = false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Contract</p>
          <button 
            class="delete" 
            aria-label="close"
            @click="showEditModal = false"
          ></button>
        </header>
        
        <section class="modal-card-body">
          <form @submit.prevent="handleEditSubmit">
            <div class="field">
              <label class="label">Start Date</label>
              <div class="control">
                <input 
                  type="datetime-local" 
                  class="input"
                  v-model="editDateLocal.start_date"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">End Date</label>
              <div class="control">
                <input 
                  type="datetime-local" 
                  class="input"
                  v-model="editDateLocal.end_date"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">User Count</label>
              <div class="control">
                <input 
                  type="number" 
                  class="input"
                  v-model="editForm.features.user_count"
                  min="1"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Camera Count</label>
              <div class="control">
                <input 
                  type="number" 
                  class="input"
                  v-model="editForm.features.camera_count"
                  min="1"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Project Count</label>
              <div class="control">
                <input 
                  type="number" 
                  class="input"
                  v-model="editForm.features.project_count"
                  min="1"
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <button type="submit" class="button is-primary">Update Contract</button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </modal>

    <!-- Add Invoice Modal -->
    <modal v-if="showInvoiceModal" @close="showInvoiceModal = false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add New Invoice</p>
          <button 
            class="delete" 
            aria-label="close"
            @click="showInvoiceModal = false"
          ></button>
        </header>
        
        <section class="modal-card-body">
          <form @submit.prevent="handleInvoiceSubmit">
            <div class="field">
              <label class="label">Due Date *</label>
              <div class="control">
                <input 
                  type="datetime-local" 
                  class="input"
                  v-model="invoiceDueDateLocal"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Currency *</label>
              <div class="control">
                <div class="select">
                  <select v-model="invoiceForm.currency" required>
                    <option value="">Select Currency</option>
                    <option value="USD">USD</option>
                    <option value="TL">TL</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label">Amount *</label>
              <div class="control">
                <input 
                  type="number" 
                  step="0.01"
                  class="input"
                  v-model="invoiceForm.amount"
                  required
                >
              </div>
            </div>

            <div class="field">
              <p class="help">* Required fields</p>
              <div class="control">
                <button type="submit" class="button is-primary">Create Invoice</button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal
  },
  data() {
    // Bugünün tarihini al
    const now = new Date();
    
    // 1 ay sonrasını hesapla
    const oneMonthLater = new Date();
    oneMonthLater.setMonth(now.getMonth() + 1);

    // 1 hafta sonrasını hesapla
    const oneWeekLater = new Date();
    oneWeekLater.setDate(now.getDate() + 7);

    // Tarihleri formatlama fonksiyonu
    const formatDateForBackend = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}T00:00:00Z`;
    };

    return {
      contracts: [],
      showAddContractModal: false,
      contractForm: {
        start_date: formatDateForBackend(now),
        end_date: formatDateForBackend(oneMonthLater),
        user_count: 1,
        camera_count: 1,
        project_count: 1,
        package_id: 1,
        currency: 'USD',
        amount: null,
        company_id: null
      },
      showEditModal: false,
      editForm: {
        id: null,
        start_date: '',
        end_date: { Valid: false, Time: '' },
        features: {
          user_count: 1,
          camera_count: 1,
          project_count: 1
        }
      },
      editDateLocal: {
        start_date: '',
        end_date: ''
      },
      showInvoiceModal: false,
      invoiceForm: {
        due_date: formatDateForBackend(oneWeekLater),
        currency: 'USD',
        amount: null,
        contract_id: null
      }
    };
  },
  computed: {
    ...mapState(["selectedCompany"]),
    startDateLocal: {
      get() {
        return this.formatToLocal(this.contractForm.start_date);
      },
      set(value) {
        this.contractForm.start_date = this.formatToBackend(value);
      }
    },
    endDateLocal: {
      get() {
        return this.formatToLocal(this.contractForm.end_date);
      },
      set(value) {
        this.contractForm.end_date = this.formatToBackend(value);
      }
    },
    invoiceDueDateLocal: {
      get() {
        return this.formatToLocal(this.invoiceForm.due_date);
      },
      set(value) {
        this.invoiceForm.due_date = this.formatToBackend(value);
      }
    }
  },
  methods:{
    getContracts(){
      this.$http.get(`contracts?page=1&size=20&company_id=${this.selectedCompany.id}`).then((response) => {
        this.contracts = response.data.data
      });
    },
    save(contract) {
      var formData = new FormData();
      formData.append("id", contract.id);
      formData.append("start_date", contract.start_date);
      if (contract.end_date.Valid) {
       formData.append("end_date", contract.end_date.Time);
      }

      if (contract.next_invoice_date.Valid) {
       formData.append("next_invoice_date", contract.next_invoice_date.Time);
      }
   
      formData.append("user_count", contract.features.user_count);
      formData.append("project_count", contract.features.project_count);
      formData.append("camera_count", contract.features.camera_count);
      

      this.$http.patch("contracts/", formData).then((response) => {
        if (response.status !== 200) {
          this.$toast.open({
            position: "top",
            type: "error",
            message:
              "Error occurred",
          });
          return;
        }
        this.$toast.open({
          position: "top",
          type: "success",
          message: "Updated",
        });
        
      });

      setTimeout(() => {
        this.getContracts();    
      }, 1000);
    

    },
    async handleContractSubmit() {
      try {
        // Form validasyonu - next_invoice_date hariç zorunlu alanlar
        if (!this.contractForm.start_date || 
            !this.contractForm.end_date || 
            !this.contractForm.package_id || 
            !this.contractForm.user_count || 
            !this.contractForm.camera_count || 
            !this.contractForm.project_count || 
            !this.contractForm.currency || 
            !this.contractForm.amount) {
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Please fill all required fields"
          });
          return;
        }

        const formData = new FormData();
        
        this.contractForm.company_id = this.selectedCompany.id;
        
        // next_invoice_date'i kontrol et ve sadece değer varsa ekle
        Object.keys(this.contractForm).forEach(key => {
          if (key === 'next_invoice_date') {
            if (this.contractForm[key]) {
              formData.append(key, this.contractForm[key]);
            }
          } else {
            formData.append(key, this.contractForm[key]);
          }
        });

        const response = await this.$http.post("contracts", formData);

        if (response.status === 200) {
          this.$toast.open({
            position: "top",
            type: "success",
            message: "Contract created successfully"
          });
          this.showAddContractModal = false;
          this.getContracts();
        }
      } catch (error) {
        console.error('Error creating contract:', error);
        this.$toast.open({
          position: "top",
          type: "error",
          message: "Error creating contract"
        });
      }
    },
    formatToLocal(backendDate) {
      if (!backendDate) return '';
      // "2025-03-22T00:00:00Z" -> "2025-03-22T00:00"
      return backendDate.slice(0, -4);
    },
    formatToBackend(localDate) {
      if (!localDate) return '';
      // "2025-03-22T00:00" -> "2025-03-22T00:00:00Z"
      return localDate + ':00Z';
    },
    editContract(contract) {
      this.editForm = {
        id: contract.id,
        start_date: contract.start_date,
        end_date: contract.end_date,
        features: { ...contract.features }
      };

      this.editDateLocal = {
        start_date: this.formatToLocal(contract.start_date),
        end_date: contract.end_date?.Valid ? this.formatToLocal(contract.end_date.Time) : ''
      };

      this.showEditModal = true;
    },
    async handleEditSubmit() {
      try {
        const formData = new FormData();
        formData.append("id", this.editForm.id);
        formData.append("start_date", this.formatToBackend(this.editDateLocal.start_date));
        
        if (this.editDateLocal.end_date && this.editDateLocal.end_date.trim() !== '') {
          formData.append("end_date", this.formatToBackend(this.editDateLocal.end_date));
        }

        formData.append("user_count", this.editForm.features.user_count);
        formData.append("project_count", this.editForm.features.project_count);
        formData.append("camera_count", this.editForm.features.camera_count);

        const response = await this.$http.patch("contracts/", formData);

        if (response.status === 200) {
          this.$toast.open({
            position: "top",
            type: "success",
            message: "Updated"
          });
          this.showEditModal = false;
          this.getContracts();
        }
      } catch (error) {
        console.error('Error updating contract:', error);
        this.$toast.open({
          position: "top",
          type: "error",
          message: "Error occurred"
        });
      }
    },
    showAddInvoiceModal(contract) {
      this.invoiceForm.contract_id = contract.id;
      this.showInvoiceModal = true;
    },
    async handleInvoiceSubmit() {
      try {
        if (!this.invoiceForm.due_date || 
            !this.invoiceForm.currency || 
            !this.invoiceForm.amount || 
            !this.invoiceForm.contract_id) {
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Please fill all required fields"
          });
          return;
        }

        const formData = new FormData();
        Object.keys(this.invoiceForm).forEach(key => {
          formData.append(key, this.invoiceForm[key]);
        });

        const response = await this.$http.post("invoices", formData);

        if (response.status === 200) {
          this.$toast.open({
            position: "top",
            type: "success",
            message: "Invoice created successfully"
          });
          this.showInvoiceModal = false;
          // İsterseniz burada invoice listesini yenileyebilirsiniz
        }
      } catch (error) {
        console.error('Error creating invoice:', error);
        this.$toast.open({
          position: "top",
          type: "error",
          message: "Error creating invoice"
        });
      }
    }
  },
  mounted() {
    this.getContracts();
  },
};
</script>

<style>

</style>
   