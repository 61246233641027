<template>
  <modal>
    <div class="tabs is-boxed">
      <ul>
        <li
          v-for="(tab, i) in tabs"
          :class="[modalActiveTabIndex === i ? 'is-active' : '']"
          :key="i"
        >
          <a @click="SET_MODAL_ACTIVE_TAB_INDEX(i)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <component :is="tabs[modalActiveTabIndex].component" />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import General from "./tabs/General.vue";
import { mapMutations, mapState } from "vuex";
import Invoices from "./tabs/Invoices.vue";
import Contracts from "./tabs/Contracts.vue";
import Users from "./tabs/Users.vue";

export default {
  components: {
    Modal,
    General,
    Invoices,
    Contracts,
    Users

  },
  data() {
    return {
      tabs: [
        {
          name: "General",
          component: General,
          isActive: true,
        },
        {
          name: "Contracts",
          component: Contracts,
          isActive: false,
        },
        {
          name: "Invoices",
          component: Invoices,
          isActive: false,
        },
        {
          name: "Users",
          component: Users,
          isActive: false,
        },
      ],
      deploymentType: "automatic",
      showAddContractModal: false,
      contractForm: {
        start_date: '',
        end_date: '',
        user_count: null,
        camera_count: null,
        project_count: null,
        package_id: null,
        currency: 'USD',
        amount: null,
        company_id: null
      }
    };
  },
  computed: {
    ...mapState(["modalActiveTabIndex"]),
  },
  methods: {
    ...mapMutations(["SET_MODAL_ACTIVE_TAB_INDEX"]),
    async handleContractSubmit() {
      try {
        const formData = new FormData();
        
        // Set company_id from the current company
        this.contractForm.company_id = this.company.id;
        
        // Add all form fields to FormData
        Object.keys(this.contractForm).forEach(key => {
          formData.append(key, this.contractForm[key]);
        });

        const response = await this.$axios.post('/admin/contracts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data) {
          // Başarılı mesajı göster
          this.$toast.success('Contract created successfully');
          this.showAddContractModal = false;
          // Contracts listesini yenile
          this.fetchCompanyContracts();
        }
      } catch (error) {
        console.error('Error creating contract:', error);
        this.$toast.error('Error creating contract');
      }
    },
    
    // Eğer yoksa, contracts'ları yenilemek için bir method ekleyin
    async fetchCompanyContracts() {
      // Contracts'ları yeniden yükle
      // Bu methodu implement edin veya mevcut bir methodu kullanın
    }
  },
};
</script>

<style scoped>
.level-item {
  flex-shrink: 1;
  padding-right: 1em;
}
</style>
