import Vue from "vue";
import Vuex from "vuex";

import { mutations } from "@/store/mutations";
import { actions } from "@/store/actions";
import { getters } from "@/store/getters";
import createPersistedState from "vuex-persistedstate";
// import LogRocket from "logrocket";
// import createPlugin from "logrocket-vuex";

// const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "vuex",
      paths: ["user"],
    }),
  ],
  state: {
    search: "",
    filters: {
      role: "",
      status: "",
      company: "",
      project: "",
      showDeleted: false,
    },
    isSaving: false,
    isLoadingSync: false,
    tabs: [
      {
        table: "users",
        name: "Users",
        isActive: true,
        childComponent: "userList",
        modal: "modalUser",
        currentPage: 1,
        size: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "companies",
        name: "Companies",
        isActive: false,
        childComponent: "companyList",
        modal: "modalCompany",
        currentPage: 1,
        size: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "projects",
        name: "Projects",
        isActive: false,
        childComponent: "projectList",
        modal: "modalProject",
        currentPage: 1,
        size: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "cameras",
        name: "Cameras",
        isActive: false,
        childComponent: "cameraList",
        modal: "modalCamera",
        currentPage: 1,
        size: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "contracts",
        name: "Contracts",
        isActive: false,
        childComponent: "contractList",
        modal: "modalContract",
        currentPage: 1,
        size: 10,
        totalRecords: 0,
        totalPages: 0,
      }
    ],
    isModalOpen: false,
    modalActiveTabIndex: 0,
    user: {
      id: null,
      fullName: "",
      isAuthenticated: false,
      permissions: [],
    },
    users: [],
    companies: [],
    contracts: [],
    cameras: [],
    projects: [],
    selectedCamera: {
      id: null,
      name: "",
      project_id: "",
      aws_bucket: "",
      aws_folder: "",
      aws_region: "",
      timezone: "",
      schedule_group: "",
      company: "",
    },
    selectedUser: {
      id: null,
      fullName: "",
      email: "",
      password: "",
      cameras: [],
      projects: [],
      roles: [],
    },
    selectedCompany: {
      id: null,
      name: "",
      awsBucket: "",
      awsRegion: "",
      awsIotUser: "",
      awsIotUserPolicy: "",
      awsUploadKey: "",
      awsUploadSecret: "",
      roles: [],
      users: [],
    },
    selectedProject: {
      id: null,
      name: "",
      company: null,
    },
    selectedContract: {
      id: 0,
      created_at: new Date(), 
      updated_at: new Date(), 
      company_id: 0, 
      user_id: 0, 
      package_id: 0, 
      start_date: new Date(),
      end_date:  new Date(), 
      amount: 0.0,
      currency: "USD",
      package_name: "",
      features: {},
      status: "",
      is_trial: false,
      next_invoice_date: null,
      is_canceled: false,
    },
    selectedInvoice: {
      id: 0,
      created_at: new Date(), 
      updated_at: new Date(), 
      company_id: 0, 
      amount: 0.0,
      currency: "",
    }
  },
  mutations,
  actions,
  getters,
});

export default store;
